<template>
    <div :id="elID"
         class="village-charts" ></div>
</template>

<script>
    export default {
        name: "EchartsView",
        props:['elID','showTitleArray','showMax','chartArr'],
        data () {
            return {
                title: '',
                indicator: [
                ],
                surveyScore: '',
            }
        },
        mounted () {
            this.toSetShowData();
        },
        watch:{
            chartArr(newVal){
                this.drawCharts();
            }
        },
        methods: {
            toSetShowData(){
                var tmpIndicator=[];
                for (var str of this.showTitleArray){
                    var tmpDic = {};
                    tmpDic.text = str;
                    tmpDic.max = Number('4');
                    tmpIndicator.push(tmpDic);
                }
                this.indicator = tmpIndicator;
                this.drawCharts()
            },
            drawCharts () {
                let elementById = document.getElementById(this.elID)
                let villageCharts = this.$echarts.init(elementById)
                let option = {
                    radar: [
                        {
                            indicator: this.indicator,
                            center: ['50%', '55%'],
                            // radius: [0,50],
                            startAngle: 90,
                            splitNumber: 4,
                            shape: 'circle',
                            name: {
                                formatter: '{value}',
                                textStyle: {
                                    color: '#000000'
                                }
                            },
                            splitArea: {
                                areaStyle: {
                                    color: ['rgba(255,255,255, 1)',
                                        'rgba(255,255,255, 1)', 'rgba(255,255,255, 1)',
                                        'rgba(255,255,255, 1)', 'rgba(255,255,255, 1)'],
                                    shadowColor: 'rgba(0, 0, 0, 0.06)',
                                    shadowBlur: 10,
                                    shadowOffsetY:0,
                                    shadowOffsetX:0
                                },

                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#EFEEF3',

                                }
                            },
                            splitLine: {
                                lineStyle: {
                                    color: 'rgba(255, 255, 255, 0.5)',

                                }
                            }
                        },
                    ],
                    series: [
                        {
                            name: '雷达图',
                            type: 'radar',
                            emphasis: {
                                lineStyle: {
                                    width: 4
                                }
                            },

                            itemStyle:{
                                normal:{
                                    color:'#30BF78', //折点颜色

                                }

                            },
                            data: [
                                {
                                    value: this.chartArr,
                                    name: '图一',
                                    symbol: 'rect',
                                    symbolSize: 5,
                                    lineStyle: {
                                        color:'#30BF78',
                                    },
                                    areaStyle: {
                                        color: 'rgba(48, 191, 120, 0.14)',

                                    },

                                }
                            ]
                        },
                    ]
                }
                villageCharts.setOption(option)
            }
        }
    }
</script>

<style lang="less" scoped>
    .village-charts {
        /*padding: 0.2rem;*/
        box-sizing: border-box;
        background-image: radial-gradient(#ffffff, #ffffff);
        font-family:PingFangSC-Regular,PingFang SC;
    }
</style>
