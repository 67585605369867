
<template>
  <div class="siteInfo">
    <div class="visit-wrap" @click="visitClick" v-if="visitShow">
      <img :src="visitbg" class="visitbg-img">
      <div class="flex visit-content">
        <div class="left">
          <div>走访调研信息</div>
          <img :src="right" class="right-img">
        </div>
        <img :src="visit" class="visit-img">
      </div>
      
    </div>
    <div class="tabs-wrap flex" :class="{'visit-hide': !visitShow}">
      <div class="tab-wrap" :class="{'active': tabindex == 0}" @click="tabindex = 0">村情信息</div>
      <div class="tab-wrap" :class="{'active': tabindex == 1}" @click="tabindex = 1">站长信息</div>
    </div>
    <div v-if="tabindex == 0">
      <div class="score-wrap flex"  v-show="isShow_SiteInfo_SiteScore">
        <div class="score-item">村情调研分数</div>
        <div class="score-item score" >{{siteInfo.villageScore}}分</div>
        <div class="score-item tag">{{siteScoreInfo}}</div>
      </div>
      <EchartsView v-show="isShow_SiteInfo_SiteScore"
                    :chartArr="[siteInfo.population,siteInfo.economic,siteInfo.reside,siteInfo.culture,siteInfo.overrall]"
                    :elID="'echert1'"
                    :showTitleArray="['人口','经济','居住','文化','综合']"
                    :show-max="'4'"
                    class="width_full echartsView"></EchartsView>
      <van-tabs color="#3F7C53" line-width="80px" line-height="0.04rem" title-active-color="#3F7C53">
        <van-tab title="基本信息">
          <div class="flex align_items_center" style="background-color: #fff;">
            <van-field :label="siteInfo.stationLocate" label-width="6rem"
                       left-icon="location-o" input-align="right"
            />
            <van-icon name="edit" color="#3F7C53" size="0.35rem"  @click="mapshow = true"/>
            <van-button type="primary" size="mini"
                        style="width: 1rem;margin:0 0.1rem"
                        @click="goNavigation"
            >去这里</van-button>
          </div>

          <van-field label="站点推荐来源" readonly v-model="siteInfo.recommendSource" input-align="right"/>
          <van-field label="站点场所性质" readonly v-model="siteInfo.placeNature" input-align="right"/>
          <div class="mt2"></div>
          <div v-if="siteInfo.villageInfo && siteInfo.villageInfo != undefined && siteInfo.villageInfo != ''"
                class="village-info">{{siteInfo.villageInfo}}</div>
        </van-tab>
        <van-tab title="重点调查">
          <div class="title">人口信息</div>
          <van-field label="户数" readonly v-model="itemsInfo.households" input-align="right"/>
          <van-field label="人数" readonly v-model="itemsInfo.peopleNum" input-align="right"/>
          <van-field label="原著居民占比" readonly v-model="itemsInfo.aboRatio" input-align="right"/>
          <div class="title">经济信息</div>
          <van-field label="人均耕地(亩)" readonly v-model="itemsInfo.perLand" input-align="right" label-width="4rem"/>
          <van-field label="户均可支配资金" readonly v-model="itemsInfo.houseDisposableFound" input-align="right" label-width="4rem"/>
          <van-field label="私家车(占户数比例)" readonly v-model="itemsInfo.carRatio" input-align="right" label-width="4rem"/>
          <div class="title">居住信息</div>
          <van-field label="居住密度" readonly v-model="itemsInfo.livingDensity" input-align="right"/>
          <div class="title">文化信息</div>
          <van-field label="特色地方文化、历史名人等" readonly v-model="itemsInfo.distinctiveCulture" input-align="right" label-width="4rem"/>
          <van-field label="地域影响" v-if="itemsInfo.distinctiveCulture == '有'" readonly v-model="itemsInfo.regionalImpact" input-align="right"/>
          <div class="mt2"></div>
          <van-field label="美好示范乡村、明星村落等" readonly v-model="itemsInfo.demonstrationVillage" input-align="right" label-width="4rem"/>
          <van-field label="示范等级" readonly  v-if="itemsInfo.demonstrationVillage == '有'" v-model="itemsInfo.demonstrationLevel" input-align="right"/>
          <div class="title">综合信息</div>
          <van-field label="村集体经济" readonly v-model="generalInfo.villageCollectiveEconomy" input-align="right"/>
          <van-field label="近年拆迁信息" readonly v-model="generalInfo.recentDemolitionInfo" input-align="right"/>
          <van-field label="集贸市场" readonly v-model="generalInfo.pedlarsMarket" input-align="right"/>
          <van-field label="快递收发点" readonly v-model="generalInfo.expressPoint" input-align="right"/>
          <van-field label="水电气等代缴点" readonly v-model="generalInfo.paymentPoint" input-align="right" label-width="4rem"/>
          <van-field label="同类站点" readonly v-model="generalInfo.similarPoint" input-align="right"/>
          <div class="title">周边金融网点</div>
          <div style="margin: 0.2rem">
            <van-tag v-for="(name , idx)  in nearFinancePoint" :key="idx" size="large" color="#ffffff" text-color="#000000" style="font-size: 15px">{{name}}</van-tag>
          </div>
          <div class="mt2"></div>
          <van-field label="最近金融网点距离(KM)" readonly v-model="itemsInfo.nearFinancePointDistance" input-align="right" label-width="4rem"/>
        </van-tab>
        <van-tab title="补充调查">
          <div class="title">补充信息</div>
          <van-field label="主要经济来源" readonly v-model="itemsInfo.mainIncomeSource" input-align="right"/>
          <van-field label="种植业类型" readonly v-model="itemsInfo.plantIndustry" input-align="right"/>
          <div class="mt2"></div>
          <van-field label="是否有养殖业" readonly v-model="itemsInfo.hasBreedIndustry" input-align="right"/>
          <van-field v-if="itemsInfo.hasBreedIndustry == '有'" label="养殖类类型" readonly v-model="itemsInfo.breedIndustryType" input-align="right"/>
          <div class="mt2"></div>
          <van-field label="是否有制造业" readonly v-model="itemsInfo.hasManufacture" input-align="right"/>
          <van-field label="制造业类型" v-if="itemsInfo.hasManufacture == '有'" readonly v-model="itemsInfo.manufactureType" input-align="right"/>
          <van-field label="主要形式" v-if="itemsInfo.hasManufacture == '有'" readonly v-model="itemsInfo.manufactureForm" input-align="right"/>
          <van-field label="有无知名企业" v-if="itemsInfo.hasManufacture == '有'" readonly v-model="itemsInfo.hasFamousCompany" input-align="right"/>
          <div class="mt2"></div>
          <van-field label="是否有服务业" readonly v-model="itemsInfo.hasServiceIndustry" input-align="right"/>
          <van-field label="服务业类型" v-if="itemsInfo.hasServiceIndustry == '有'"  readonly v-model="itemsInfo.serviceIndustryType" input-align="right"/>
          <van-field label="周边旅游景点" v-if="itemsInfo.hasServiceIndustry == '有'" readonly v-model="itemsInfo.hasNearScenicSpots" input-align="right"/>
          <div class="title">参考项目</div>
          <van-field label="网络信号" readonly v-model="referItems.netSignal" input-align="right"/>
          <van-field label="是否为乡镇驻地村" readonly v-model="referItems.townResident" input-align="right" label-width="4rem"/>
          <van-field label="行方建议" readonly v-model="itemsInfo.bankSuggest" input-align="right"/>
        </van-tab>
      </van-tabs>
    </div>
    <div v-if="tabindex == 1">
      <div class="score-wrap flex" v-show="isShow_SiteInfo_MasterScore">
        <div class="score-item">站长调研分数</div>
        <div class="score-item score">{{masterInfo.masterScore}}分</div>
        <div class="score-item tag">{{masterScoreInfo}}</div>
      </div>
      <div v-show="isShow_SiteInfo_MasterScore" class="zzScore width_full">
        <div class="flex zzScoreTop" >
          <div class="VDS-ShadowBg zzScoreTopTag">
            <div class="zzScoreTopTagRect" style="background-color:#3F7C53 "></div>
            <div style="color:#3F7C53;">站长情况</div>
          </div>
          <div class="VDS-ShadowBg zzScoreTopTag zzScoreTopTagMargin">
            <div class="zzScoreTopTagRect" style="background-color:#F5A623 "></div>
            <div style="color:#F5A623;">家庭情况</div>
          </div>
          <div class="VDS-ShadowBg zzScoreTopTag zzScoreTopTagMargin">
            <div class="zzScoreTopTagRect" style="background-color:#F7D470 "></div>
            <div style="color:#F7D470">其他信息</div>
          </div>
        </div>
        <div class="width_full flex" style="justify-content: center;position: relative;z-index: 10;height: 135px;">
          <EchartsHorizontalHistogram
                  :elID="'echert2'"
                  :barWidth="20"
                  :chart-arr="[[this.managerScore],[this.familyScore],[this.extraScore]]"
                  style="width: 80%;height: 135px;"></EchartsHorizontalHistogram>
        </div>
      </div>
      <div>
        <div class="title">个人信息</div>
        <van-field label="姓名" readonly v-model="masterInfo.name" input-align="right"/>
        <van-field label="身份证号" readonly v-model="masterInfo.idCard" input-align="right"/>
         <van-field label="年龄" readonly v-model="masterInfo.age" input-align="right"/>
         <van-field label="农历生日" readonly v-model="birthdayLc" input-align="right" v-if="masterInfo.birthdayLc"/>
        <van-field label="电话" readonly v-model="masterInfo.phoneNo" input-align="right" :right-icon="telicon" @click="phoneClick(masterInfo.phoneNo)"/>
        <div class="mt2"></div>
        <van-field label="是否为本地户口" readonly v-model="masterInfo.isLocalResidence" input-align="right" label-width="4rem"/>
        <van-field label="是否在本村居住" readonly v-model="masterInfo.liveVillage" input-align="right" label-width="4rem"/>
        <van-field label="是否为党员" readonly v-model="masterInfo.partyMember" input-align="right"/>
        <van-field label="是否为村干部" readonly v-model="masterInfo.villageCadre" input-align="right"/>
        <div class="mt2"></div>
        <van-field label="教育状况" readonly v-model="masterInfo.education" input-align="right"/>
        <van-field label="婚姻状况" readonly v-model="masterInfo.marital" input-align="right"/>
        <van-field label="营业执照资质" readonly v-model="masterInfo.licenseQualify" input-align="right"/>
        <van-field label="职业经历" readonly v-model="masterInfo.professionHistory" input-align="right"/>
        <div class="mt2"></div>
        <div class="title">家庭信息</div>
        <van-field label="家属姓名" readonly v-model="masterInfo.linkmanName" input-align="right"/>
        <van-field label="家属关系" readonly v-model="masterInfo.linkmanRelation" input-align="right"/>
        <van-field label="家属电话" readonly v-model="masterInfo.linkmanPhone" input-align="right" :right-icon="telicon" @click="phoneClick(masterInfo.linkmanPhone)"/>
        <div class="mt2"></div>
        <van-field label="家庭总资产" readonly v-model="masterInfo.familyAssets" input-align="right"/>
        <van-field label="家庭年收入" readonly v-model="masterInfo.familyAnnualIncome" input-align="right"/>
        <van-field label="家庭收入来源" readonly v-model="masterInfo.familyIncomeSource" input-align="right"/>
        <van-field label="亲属在村委、乡镇任职" readonly v-model="masterInfo.relaInVillageOffice" input-align="right" label-width="4rem"/>
        <van-field label="家属任民间组织负责人" readonly v-model="masterInfo.headOfCivil" input-align="right" label-width="4rem"/>
        <div class="mt2"></div>
        <div class="title">其它信息</div>
        <van-field v-for="dict in showOtherInfoArray" :key="dict.name" :label=dict.name readonly  :value=dict.value input-align="right" label-width="5rem"/>

        <div class="mt2"></div>
        <div class="title">重点调查事项（必选） </div>
        <van-field label="是否有非法集资、民间借贷等行为" readonly v-model="masterInfo.MustNo" input-align="right" label-width="5rem"/>
        <van-field label="是否有公安机关不良记录或犯罪记录" readonly v-model="masterInfo.MustNo" input-align="right" label-width="5rem"/>
        <van-field label="是否参与赌博活动" readonly v-model="masterInfo.MustNo" input-align="right" label-width="4rem"/>
        <van-field label="是否有不良征信记录" readonly v-model="masterInfo.MustNo" input-align="right" label-width="4rem"/>

      </div>
    </div>


    <van-popup v-model="mapshow" v-if="mapshow" position="top">
      <div class="mapDiv">
        <div class="close">
          <i @click="closeMapClick">
            <van-icon name="cross" />
          </i>
        </div>
        <baidu-map style="width: 100%;height: 90%"
                   :center="center" :zoom="zoom"
                   :scroll-wheel-zoom="true"
                   @ready="onBaiduMapReady"
                   @click="getClickInfo"
                   @moving="syncCenterAndZoom"
                   @moveend="syncCenterAndZoom"
                   @zoomend="syncCenterAndZoom"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"> </bm-navigation>
          <bm-marker :position="center" :dragging="true"> </bm-marker>
          <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"> </bm-geolocation>
        </baidu-map>
      </div>

    </van-popup>

        
     
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Toast, Field, Collapse, CollapseItem, Tab, Tabs ,Tag } from 'vant'
  import { getStationBaseInfo , getSurveyItems,getDictByPage,
    getDesenseMasterInfoByNo, getprobeSubTaskList,updateStation} from '@/utils/api'
  import common from '@/utils/common'
  import EchartsView from "../ECharts/EchartsRadar";
  import EchartsHorizontalHistogram from "../ECharts/EchartsHorizontalHistogram";
  import {uwStatisticAction,appAction} from "../../utils/util";
  import {MAP_NAVIGATION} from "../../utils/constantNum";
export default {
  components:{
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    EchartsView,
    EchartsHorizontalHistogram
  },
  data() {
    return {
      dicInfo:{},
      masterDicInfo:{},
      itemsInfo:{},
      siteInfo:{},
      siteScoreInfo:'',
      masterScoreInfo:'',
      generalInfo: {
      },
      referItems:{},
      masterInfo: {},
      activeNames:'',
      nearFinancePoint:[],
      showOtherInfoArray:[],
      managerScore: '0',
      familyScore: '0',
      extraScore: '0',
      visitbg: require('@/assets/img/site/visitbg.png'),
      visit: require('@/assets/img/site/visit.png'),
      right: require('@/assets/img/site/right.png'),
      tabindex: 0,
      visitShow: false,
      surveyLength: null,
      areaCode: null,
      subTaskCode: null,
      taskId: null,
      telicon: require('@/assets/img/site/telicon.png'),
      montharr: ['正月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','腊月'],
      dayarr: ['初一', '初二', '初三','初四','初五','初六','初七','初八','初九','初十','十一','十二','十三','十四','十五',
        '十六','十七','十八','十九','廿十','廿一','廿','廿三','廿四','廿五','廿六','廿七','廿八','廿九','三十'],
      birthdayLc: '',
      mapshow:false,
      center:{},
      zoom:20,
      BMap: null, // 地图组件是否就绪
    }
  },
  computed:{ 
    ...mapGetters([
      'isShow_SiteInfo_MasterScore',
      'isShow_SiteInfo_SiteScore',
    ])
  },
  mounted() {
    // 获取配置信息
    this.getDicInfo();
    uwStatisticAction('/site/siteInfo','站点信息')
  },
  methods:{
    syncCenterAndZoom(e) {
      // 返回地图当前的缩放级别
      this.zoom = e.target.getZoom()
    },
    //初始化地图
    onBaiduMapReady(e) {
      let that =  this
      that.BMap = e.BMap
    },
    //地图点击事件
    getClickInfo(e) {
      let that =  this
      // 调整地图中心位置
      that.center.lng = e.point.lng
      that.center.lat = e.point.lat
      // 此时已经可以获取到BMap类
      if (that.BMap) {
        // Geocoder() 进行地址解析
        // 创建地址解析器的实例
        const geoCoder = new this.BMap.Geocoder()
        // getLocation() 利用坐标获取地址的详细信息
        // getPoint() 获取位置对应的坐标
        geoCoder.getLocation(e.point, function(res) {
          console.log('获取经纬度', e.point, '获取详细地址', res)
          that.mapshow = false;
          let {lng,lat} = e.point;
          let data = {
            lng,lat,
            stationNo:that.$route.query.stationNo,
            stationAddress:res.address
          }
          updateStation(data).then(res=>{
            console.log('测试', res);
            if(res.code===200){
              that.getInfo();
            }
          })
        })
      }
    },
    closeMapClick(){
      this.mapshow = false
    },
    phoneClick(phoneNo) {
      window.location.href = "tel:" + phoneNo
    },
    visitClick(){
      if(this.surveyLength == 1){
        this.$router.push({name: 'surveyDetail', query:{subTaskCode: this.subTaskCode, taskId: this.taskId }})
      }
      if(this.surveyLength > 1){
        this.$router.push({name: 'visitsubSurveyListDetail', query:{areaCode: this.areaCode}})
      }
    },
    // 获取村情基本信息
    getInfo() {
      getStationBaseInfo(this.$route.query.stationNo).then(res=>{
        if(res.code === 200){
          console.log('数据111111', res.data)
          this.siteInfo = res.data
          this.siteInfo.recommendSource = this.getValue(this.dicInfo.recommendSource,this.siteInfo.recommendSource);
          this.siteInfo.placeNature = this.getValue(this.dicInfo.placeNature,this.siteInfo.placeNature);
          this.siteScoreInfo = this.villageStartShow(this.siteInfo.villageScore);
          this.toGetprobeSubTaskList(res.data.villageCode)
          let { lat, lng } = res.data;
          this.center = {lat,lng}
        }
      })
    },
    toGetprobeSubTaskList(areaCode) {
      getprobeSubTaskList({areaCode}).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200 && res.data.data.length != 0){
            this.visitShow = true
            this.surveyLength = res.data.data.length
            this.areaCode = areaCode
            this.subTaskCode = res.data.data[0].taskCode
            this.taskId = res.data.data[0].id
          }
        }
      })
    },
    // 获取村情收集信息
    getItemsInfo(){
     getSurveyItems(this.$route.query.stationNo).then(res=>{
       if(res.code == 200){

         // 获取站长配置
         this.getMasterDicInfo();

         this.itemsInfo = res.data;
         this.generalInfo = JSON.parse(this.itemsInfo.generalInfo);

         this.referItems = JSON.parse(this.itemsInfo.referItems);
         this.itemsInfo.distinctiveCulture = this.itemsInfo.distinctiveCulture == 1 ? '有' : '无';
         this.itemsInfo.demonstrationVillage = this.itemsInfo.demonstrationVillage == 1 ? '有' : '无';
         this.generalInfo.villageCollectiveEconomy = this.generalInfo.villageCollectiveEconomy == 1 ? '有' : '无';
         this.generalInfo.recentDemolitionInfo     = this.generalInfo.recentDemolitionInfo == 1 ? '有' : '无';
         this.generalInfo.pedlarsMarket            = this.generalInfo.pedlarsMarket == 1 ? '有' : '无';
         this.generalInfo.expressPoint             = this.generalInfo.expressPoint == 1 ? '有' : '无';
         this.generalInfo.paymentPoint             = this.generalInfo.paymentPoint == 1 ? '有' : '无';
         this.generalInfo.similarPoint             = this.generalInfo.similarPoint == 1 ? '有' : '无';
         this.itemsInfo.hasBreedIndustry         = this.itemsInfo.hasBreedIndustry == 1 ? '有' : '无';
         this.itemsInfo.hasManufacture         = this.itemsInfo.hasManufacture == 1 ? '有' : '无';
         this.itemsInfo.hasFamousCompany         = this.itemsInfo.hasFamousCompany == 1 ? '有' : '无';
         this.itemsInfo.hasServiceIndustry         = this.itemsInfo.hasServiceIndustry == 1 ? '有' : '无';
         this.itemsInfo.hasNearScenicSpots         = this.itemsInfo.hasNearScenicSpots == 1 ? '有' : '无';
         this.referItems.netSignal         = this.referItems.netSignal == 1 ? '好' : '差';
         this.referItems.townResident         = this.referItems.townResident == 1 ? '是' : '否';
         this.itemsInfo.aboRatio = this.getValue(this.dicInfo.aboRatio,this.itemsInfo.aboRatio);
         this.itemsInfo.houseDisposableFound = this.getValue(this.dicInfo.houseDisposableFound,this.itemsInfo.houseDisposableFound);
         this.itemsInfo.carRatio = this.getValue(this.dicInfo.carRatio,this.itemsInfo.carRatio);
         this.itemsInfo.livingDensity = this.getValue(this.dicInfo.livingDensity,this.itemsInfo.livingDensity);
         this.itemsInfo.regionalImpact = this.getValue(this.dicInfo.regionalImpact,this.itemsInfo.regionalImpact);
         this.itemsInfo.demonstrationLevel = this.getValue(this.dicInfo.demonstrationLevel,this.itemsInfo.demonstrationLevel);
         for (var keyString of this.itemsInfo.nearFinancePoint.split(',')){
           this.nearFinancePoint.push(this.getValue(this.dicInfo.nearFinancePoint,keyString));
         }
         this.itemsInfo.nearFinancePointDistance = this.getValue(this.dicInfo.nearFinancePointDistance,this.itemsInfo.nearFinancePointDistance);
         this.itemsInfo.mainIncomeSource = this.getValue(this.dicInfo.mainIncomeSource,this.itemsInfo.mainIncomeSource);
         this.itemsInfo.plantIndustry = this.getValue(this.dicInfo.plantIndustry,this.itemsInfo.plantIndustry);
         this.itemsInfo.manufactureForm = this.getValue(this.dicInfo.manufactureForm,this.itemsInfo.manufactureForm);
         this.itemsInfo.manufactureType = this.getValue(this.dicInfo.manufactureType,this.itemsInfo.manufactureType);
         this.itemsInfo.breedIndustryType = this.getValue(this.dicInfo.breedIndustryType,this.itemsInfo.breedIndustryType);

         if(this.itemsInfo.manufactureType.length > 15){
           this.itemsInfo.manufactureType = this.itemsInfo.manufactureType.substring(0,15)+'...'
         }
         this.itemsInfo.serviceIndustryType = this.getValue(this.dicInfo.serviceIndustryType,this.itemsInfo.serviceIndustryType);
         this.itemsInfo.bankSuggest = this.getValue(this.dicInfo.bankSuggest,this.itemsInfo.bankSuggest);
       }
     })
    },
    // 获取配置选项
    getDicInfo(){
      getDictByPage('stationPage').then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            this.dicInfo = JSON.parse(data.data);
            // 获取站点信息
            this.getItemsInfo();
            // 获取站点信息
            this.getInfo();
          }
        }
      })
    },
    // 获取站长信息
    getMasterInfo(){
      getDesenseMasterInfoByNo({stationNo:this.$route.query.stationNo}).then(res=>{
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            this.masterInfo = data.data;
            this.masterInfo.MustNo = '否';
            this.masterInfo.isLocalResidence         = this.masterInfo.isLocalResidence == 1 ?       '是' : '否';
            this.masterInfo.liveVillage         = this.masterInfo.liveVillage == 1 ?                 '是' : '否';
            this.masterInfo.partyMember         = this.masterInfo.partyMember == 1 ?                 '是' : '否';
            this.masterInfo.villageCadre         = this.masterInfo.villageCadre == 1 ?               '是' : '否';
            this.masterInfo.relaInVillageOffice         = this.masterInfo.relaInVillageOffice == 1 ? '是' : '否';
            this.masterScoreInfo = this.masterStartShow(this.masterInfo.masterScore);
            this.masterInfo.education = this.getValue(this.masterDicInfo.education,this.masterInfo.education);
            this.masterInfo.marital = this.getValue(this.masterDicInfo.marital,this.masterInfo.marital);
            this.masterInfo.licenseQualify = this.getValue(this.masterDicInfo.licenseQualify,this.masterInfo.licenseQualify);
            this.masterInfo.linkmanRelation = this.getValue(this.masterDicInfo.linkmanRelation,this.masterInfo.linkmanRelation);
            this.masterInfo.familyAssets = this.getValue(this.masterDicInfo.familyAssets,this.masterInfo.familyAssets);
            this.masterInfo.familyAnnualIncome = this.getValue(this.masterDicInfo.familyAnnualIncome,this.masterInfo.familyAnnualIncome);
            this.masterInfo.familyIncomeSource = this.getValue(this.masterDicInfo.familyIncomeSource,this.masterInfo.familyIncomeSource);
            this.masterInfo.headOfCivil = this.getValue(this.masterDicInfo.headOfCivil,this.masterInfo.headOfCivil);
            this.masterInfo.detailScore = JSON.parse(this.masterInfo.detailScore)
            this.managerScore = this.masterInfo.detailScore.managerScore;
            this.familyScore  =  this.masterInfo.detailScore.familyScore;
            this.extraScore   =  this.masterInfo.detailScore.extraScore;
            if(this.masterInfo.birthdayLc){
              this.birthdayLc = this.montharr[Number(this.masterInfo.birthdayLc.substr(0,2))-1] + this.dayarr[Number(this.masterInfo.birthdayLc.substr(2,2))-1]
            }

            let selectedOtherArray = this.masterInfo.otherInfo.split(',');
            for (let oneDict of this.masterDicInfo.otherInfo) {
              var  vote = {};
              vote.name = oneDict.dictValue;
              vote.value = '否';
              for (let selectString of selectedOtherArray){
                if (oneDict.dictKey == selectString){
                  vote.value = '是';
                  break;
                }
              }
                this.showOtherInfoArray.push(vote);
              }
          }
        }
      })
    },
    // 获取配置文字
    getValue(array,keyString){
      for (let oneDict of array) {
        if (oneDict.dictKey == keyString){
          return oneDict.dictValue;
        }
      }
      return  '';
    },
    // 获取站点评分等级
    villageStartShow(score) {
      if(score > 100){
        return '卓越'
      } else if(score > 90){
        return '优质'
      } else if(score > 80 ){
        return '良好'
      } else if(score >= 60 ){
        return '达标'
      } else if(score < 60){
        return '未达标'
      } else {
        return ''
      }
    },
    // 获取站长评分等级
    masterStartShow(score) {
      if(score > 90){
        return '卓越'
      } else if(score > 80){
        return '优质'
      } else if(score > 70 ){
        return '良好'
      } else if(score >= 60 ){
        return '达标'
      } else if(score < 60){
        return '未达标'
      } else {
        return ''
      }
    },
    // 获取站长配置项
    getMasterDicInfo(){
      getDictByPage('masterPage').then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            let json = JSON.parse(data.data)
              this.masterDicInfo = json;
            console.log(this.masterInfo)
            // 获取站长信息
            this.getMasterInfo();
          }
        }
      })
    },
    //出这里
    goNavigation(){
      let data = {
        lng:this.center.lng,
        lat:this.center.lat,
        address:this.siteInfo.stationAddress,
      }
      appAction(MAP_NAVIGATION,data)
    }
  }
}
</script>

<style lang="less" scoped>
.siteInfo{
  height: 100%;
  background: #f5f5f5;
  font-size: 0.3rem;
  .echartsView{
    height: 200px;
    padding: 0.2rem 0 0.4rem 0;

  }
  .visit-hide{
    height: 1.74rem !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    .tab-wrap{
      margin: 0.25rem 0.24rem 0 !important;
    }
  }
  .tabs-wrap{
    height: 2.26rem;
    justify-content: space-between;
    background: #fff;
    border-top-left-radius: 1.02rem;
    border-top-right-radius: 1.02rem;
    margin-bottom: -0.3rem;
    .tab-wrap{
      width: 3.39rem;
      height: 0.74rem;
      line-height: 0.74rem;
      text-align: center;
      background: #F5F5F5;
      color: #333;
      border-radius: 0.4rem;
      margin: 0.82rem 0.24rem 0;
      font-size: 0.3rem;
    }
    .active{
      background: #3F7C53;
      color: #fff;
    }
  }
  

  .visit-wrap{
    position: relative;
    .visitbg-img{
      width: 7.5rem;
    }
    .right-img{
      width: 0.4rem;
    }
    .visit-img{
      width: 1.46rem;
    }
    .visit-content{
      width: 6.9rem;
      height: 1.86rem;
      position: absolute;
      left: 0.3rem;
      top: 0.23rem;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 0.48rem 0.3rem 0.4rem;
      .left{
        color: #fff;
        div{
          font-size: 0.36rem;
          margin-bottom: 0.16rem;
        }
      }
    }
  }
  .zzScore{
    background-color: white;
    height: 160px;
    .zzScoreTop{
      justify-content: center;
      background-color: white;
      position: relative;
      z-index: 999;
      margin-bottom: -20px;
      .zzScoreTopTag{
        width: 75px;
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 20px;
        justify-content: space-around;
        font-size: 10px;
        .zzScoreTopTagRect{
          width: 8px;height: 8px;border-radius: 2px;
        }
      }
      .zzScoreTopTagMargin{
        margin-left: 10px;
      }
    }
  }
  .item{
    .van-field{
      border-bottom: 0.01rem solid #f5f5f5;
    }
  }
  .mt2{
    height: 0.2rem;
    background: #f5f5f5;
  }
  .title {
    text-align: left;
    background: #FFFFFF;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0 0.3rem;
    color: #333333;
    font-weight: bold;
    font-size: 15px;
  }
  div.title:before{
    width: 10px;
    height: 10px;
    color: #3F7C53;
    content:'| '
  }
  .village-info{
    background: #fff;
    color: #333333;
    font-size: 15px;
    padding: 0.3rem;
  }
  .score-wrap{
    padding: 0.3rem;
    background: #fff;
    margin-top: 0.2rem ;
    .score-item{
      flex:1;
      text-align: center;

    }
    .score{
      text-align: right;
      color: red;
      font-weight: bold;
      font-size: 0.3rem;
    }
    .tag{
      /*border:0.02rem solid #aaa;*/
    }
  }
  .item-title{
    background: #f5f5f5;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0 0.2rem;
  }
  .item-content{
    background: #fff;
    padding: 0.1rem 0.2rem;
    font-size: 0.3rem;
    color: #333;
  }
  .VDS-ShadowBg{
    background: url("../../assets/img/common/shadowBg.png");
    background-size: 100% 100%;
  }

}
.mapDiv{
  background:rgba(0,0,0,0.3);
  padding: 10px;
  width: 375px;
  // height: 667px;
  height: 100vh;
  .el-vue-amap-container, .el-vue-amap-container .el-vue-amap{
    height: calc(100vh - 2rem);
  }
}
.close {
  text-align: right;
  margin-bottom: 14px;
  .van-icon {
    background: url("../../assets/img/close.png");
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
  }
}
</style>
<style>
  .siteInfo .van-tab__text{
    font-size: 15px;
  }
  .siteInfo .van-cell{
    min-height: 48px;
  }
  .siteInfo .van-cell__title{
    font-size: 15px;
    color: #333333;
  }
  .siteInfo .van-field__control {
    font-size: 15px;
  }
  .siteInfo .van-radio{
    font-size: 15px;
  } 
  .siteInfo .van-field__clear, .van-field__right-icon{
    line-height: 0.35rem;
  }
  
</style>
